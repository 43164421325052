import React from "react";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import StatusTag from "../Components/StatusTag";

const Confirmation = ({ attestationSummaryUrl }) => {
  return (
    <>
      <div className="flex flex-column gap--24">
        <div className="t--500 mb--8">Attestations Submitted</div>
        <div className="t--md">Your have succefully submitted attestations. View the Attestation Summary below:</div>
        <div>
          <a className="t--anchor t--blue" href={attestationSummaryUrl}>
            View Attestation Summary
          </a>
        </div>
        <div className="t--md">
          Need to change or make an update to an attestation or document submission? Please{" "}
          <a
            href="https://help.340besp.com/en/articles/8808065-frequently-asked-questions-faqs"
            target="_blank"
            className="t--blue t--anchor"
          >
            click here
          </a>{" "}
          to chat with our support team or email us at{" "}
          <a href="mailto:support@340besp.com" className="t--blue t--anchor">
            support@340besp.com
          </a>
          .
        </div>
      </div>
    </>
  );
};

export default Confirmation;
