import _ from "lodash";

export default class Http {
  static fetchEntityAttestations = (organizationId, entityId, setAttestations, setNotice) => {
    fetch(`/organization/${organizationId}/entities/${entityId}/attestations`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setAttestations(data.attestations);
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  };

  static fetchOrgAttestations = (organizationId, setAttestations, setNotice) => {
    fetch(`/organization/${organizationId}/attestations`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setAttestations(data.attestations);
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  };

  static submitPharmacyAgreements = (
    organizationId,
    entityId,
    pharmacyUploads,
    setPharmacyUploads,
    attestations,
    setAttestations,
    setNotice
  ) => {
    const formData = new FormData();
    Object.entries(pharmacyUploads).forEach(([pharmacyId, file]) => {
      formData.append(`pharmacy_agreements[${pharmacyId}]`, file);
    });

    fetch(`/organization/${organizationId}/entities/${entityId}/state_attestations/bulk_upload`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setPharmacyUploads({});
        this.#updateAttestations(data.state_attestations, attestations, setAttestations);
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  };

  static submitStateAttestations = (
    organizationId,
    entityId,
    attestations,
    setAttestations,
    signature,
    title,
    entityName,
    signaturePNG,
    attestingPharmacies,
    setAttestingPharmacies,
    setNotice,
    setPharmacyAgreementUrl
  ) => {
    fetch(`/organization/${organizationId}/entities/${entityId}/state_attestations/bulk_attest`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        state_attestations: {
          pharmacy_ids: Object.keys(attestingPharmacies),
          signed_by: signature,
          title: title,
          entityName: entityName,
          signature_png: signaturePNG,
        },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        this.#updateAttestations(data.state_attestations, attestations, setAttestations);
        setPharmacyAgreementUrl(data.attestation_agreement_url);
        setAttestingPharmacies({});
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  };

  static getEntityStats = (organizationId, entityId, setStats, setNotice) => {
    fetch(`/organization/${organizationId}/entities/${entityId}/attestations/stats`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setStats(data.stats);
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  };

  static getOrgStats = (organizationId, setStats, setNotice) => {
    fetch(`/organization/${organizationId}/attestations/stats`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setStats(data.stats);
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  };

  static #updateAttestation(updatedAttestation, attestations, setAttestations) {
    const newAttestations = attestations.map((attestation) => {
      if (attestation.pharmacy_id === updatedAttestation.pharmacy_id) {
        return updatedAttestation;
      }
      return attestation;
    });

    setAttestations(newAttestations);
  }

  static #removeSubmittedAttestations(submittedAttestationIds, attestations, setAttestations) {
    const newAttestations = attestations.filter((attestation) => {
      return !submittedAttestationIds.includes(attestation.id);
    });

    setAttestations(newAttestations);
  }

  static #updateAttestations(updatedAttestations, attestations, setAttestations) {
    const updatedAttestationsMap = new Map(
      updatedAttestations.map((attestation) => [attestation.pharmacy_id, attestation])
    );

    const newAttestations = attestations.map(
      (attestation) => updatedAttestationsMap.get(attestation.pharmacy_id) || attestation
    );

    setAttestations(newAttestations);
  }
}
