import React, { useState, useRef, useEffect } from "react";
import StyledCheckbox from "../Components/StyledCheckbox";
import CustomTextField from "../Components/CustomTextField";
import moment from "moment";
import SignaturePad from "signature_pad";

const SignAndAttest = ({
  signature,
  setSignature,
  signaturePNG,
  setSignaturePNG,
  acceptedTerms,
  setAcceptedTerms,
  userName,
  entity,
  attestingPharmacies,
  entityName,
  setEntityName,
  title,
  setTitle,
}) => {
  const handleSignature = (e) => {
    setSignature(e.target.value);
  };
  const sigCanvas = useRef(null);
  const signaturePad = useRef(null);

  useEffect(() => {
    signaturePad.current = new SignaturePad(sigCanvas.current, {});
    window.onresize = resizeCanvas;

    signaturePad.current.onEnd = () => {
      setSignaturePNG(signaturePad.current.toDataURL());
    };

    resizeCanvas();
  }, []);

  const resizeCanvas = () => {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);

    sigCanvas.current.width = sigCanvas.current.offsetWidth * ratio;
    sigCanvas.current.height = sigCanvas.current.offsetHeight * ratio;
    sigCanvas.current.getContext("2d").scale(ratio, ratio);
    handleClear();
  };

  const handleClear = () => {
    signaturePad.current.clear();
  };
  // TODO: How do we determine title?
  const userTitle = "340B Administrator";

  const renderPharmacies = () => {
    return attestingPharmacies.map((pharmacy, i) => {
      return (
        <tr key={pharmacy.pharmacy_id} className="sm">
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.pharmacy_address}</div>
          </td>
          <td className="t--md">{pharmacy.npi || "--"}</td>
        </tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="attestation_modal__table-container" style={{ height: 320 }}>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Pharmacy Name</th>
              <th>NPI</th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
    );
  };

  const renderSignature = () => {
    if (signature && signature.length) {
      return signature;
    }
    return "[Insert Name]";
  };

  const renderEntityName = () => {
    if (entityName && entityName.length) {
      return entityName;
    }
    return "[Insert Covered Entity Name]";
  };

  const renderUserTitle = () => {
    if (title && title.length) {
      return title;
    }
    return "[Insert Title]";
  };

  return (
    <>
      <div>
        <div className="t--500 mb--8">Attestation Under Penalty of Perjury</div>
        <div className="t--md">
          I, <span className="t--500">{renderSignature()}</span>, <span className="t--500">{renderUserTitle()}</span> of{" "}
          <span className="t--500">{renderEntityName()}</span>, represent and warrant that I am authorized by{" "}
          <span className="t--500">{renderEntityName()}</span> to submit this attestation on behalf of{" "}
          <span className="t--500">{renderEntityName()}</span>.
          <br />
          <br />
          <span className="t--500">{renderEntityName()}</span> contracts with certain pharmacies (hereinafter, “Contract
          Pharmacies”) to dispense 340B-priced drugs. I hereby attest that, under the contracts entered into between{" "}
          <span className="t--500">{renderEntityName()}</span> and each of its Contract Pharmacies listed below,{" "}
          <span className="t--500">{renderEntityName()}</span> retains legal title to all Sanofi 340B-priced drugs until
          such Contract Pharmacies dispense those 340B-priced drugs to{" "}
          <span className="t--500">{renderEntityName()}</span>'s patients.
          <br />
          <br />
          <span className="t--500">{renderEntityName()}</span> acknowledges that it may be audited to verify the
          accuracy of this attestation, consistent with 42 U.S.C. § 256b.{" "}
          <span className="t--500">{renderEntityName()}</span> further acknowledges that it may be liable to
          Sanofi-Aventis U.S., L.L.C. in the event that this attestation is inaccurate.
          <br />
          <br />
          Pursuant to 28 U.S.C. § 1746, I declare under penalty of perjury that the foregoing is true and correct.
        </div>
      </div>

      <div>
        <div className="t--500 mb--16">Submission Summary</div>
        {renderTable()}
      </div>

      <div>
        <div className="t--500 t--md mb--8">Electronic Signature Acknowledgment</div>
        <div className="flex align-items-start gap--8">
          <StyledCheckbox checked={acceptedTerms} onChange={() => setAcceptedTerms((x) => !x)} />
          <div
            className="t--sm"
            onClick={() => {
              setAcceptedTerms((x) => !x);
            }}
          >
            By selecting this box I agree that my electronic signature will be enforceable as and to the full extent of
            a manual/handwritten signature and will serve as an original for enforcement and enforceability of this
            Attestation, whether in court, alternative dispute resolution, or otherwise.
          </div>
        </div>
      </div>

      <div>
        <div className="t--500 mb--8">Electronic Signature</div>
        <div className="t--md">
          Executed on: <span>{moment().format("MM/DD/YYYY")}</span>
        </div>
      </div>

      <div className="flex gap--24">
        <CustomTextField
          label="Title"
          margin="none"
          variant="outlined"
          id="title"
          name="title"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="flex-grow-1"
        />
        <CustomTextField
          label="Full Name"
          margin="none"
          variant="outlined"
          id="full_name"
          name="full_name"
          required
          value={signature}
          onChange={handleSignature}
          className="flex-grow-1"
        />
      </div>
      <div className="flex gap--24">
        <CustomTextField
          label="Covered Entity Name"
          margin="none"
          variant="outlined"
          id="covered_entity_name"
          name="covered_entity_name"
          required
          value={entityName}
          onChange={(e) => setEntityName(e.target.value)}
          style={{ flexGrow: 1, flexBasis: "50%" }}
        />
        <div style={{ flexGrow: 1, flexBasis: "50%" }}></div>
      </div>
      <div className="flex gap--24">
        <div className="t--500 mb--8">Please Sign below</div>
      </div>
      <div className="flex gap--24">
        <canvas
          ref={sigCanvas}
          style={{ width: "100%", height: 150, borderRadius: 12, backgroundColor: "#f2f5f8" }}
        ></canvas>
        <textarea style={{ display: "none" }} defaultValue={signaturePNG}></textarea>
      </div>
      <div className="flex gap--24">
        <div className="btn btn--small btn--white" style={{ marginTop: 10 }} onClick={handleClear}>
          Clear
        </div>
      </div>
    </>
  );
};

export default SignAndAttest;
